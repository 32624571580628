<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list">
      <tabs-form-data
        ref="tabsFormData"
        :get-modal-mediacao="getModalMediacao"
        :cancelar-contrado-mediacao="cancelarContradoMediacao"
        :get-modal-motivo="getModalMotivo"
        :open-visit-detail="openVisitDetail"
        :open-proposal-detail="openProposalDetail"
        :open-prices="openPrices"
        :get-modal-acesso="getModalAcesso"
      />

      <proposal-detail
        ref="detailProposal"
        :class="{'show': showProposalDetail}"
        :show-msg-error-request="showMsgErrorRequest"
        :show-msg-success-request="showMsgSuccessRequest"
        @close-detail="closeModalDetail"
      />
    </div>

    <portal
      to="content-renderer-sidebar-left"
    >
      <form-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :redirect-page-back="redirectPageBack"
        :save-form="saveForm"
        :cancel-form="cancelForm"
        :action-form="actionForm"
        :get-modal-portais-listing="getModalPortaisListing"
        :get-modal-rating-listing="getModalRatingListing"
        :create-new-visit="createNewVisit"
        :change-agent="changeAgent"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <component
      :is="ModalPortaisListing"
      ref="modalPortaisListing"
      :get-modal-rating-listing="getModalRatingListing"
      :iso="countryApp"
      :config-app="appConfig"
    />

    <component
      :is="ModalRatingListing"
      v-if="showModalRating === true"
      ref="modalRatingListing"
      :rating="rating"
      :form-mode-view="formModeView"
      :iso="countryApp"
    />

    <component
      :is="ModalPlanosComunicacao"
      v-if="showModalPlanos === true"
      ref="modalPlanosComunicacao"
      :planos="planos"
    />

    <component
      :is="ModalMediacaoListing"
      v-if="showModalMediacao === true"
      ref="modalMediacaoListing"
      :show-button-next="showButtonNext"
      :data-start="dtaStart"
      :data-months="months"
      :data-end="dtaEnd"
      :iso="countryApp"
    />

    <component
      :is="ModalSeguimentoListing"
      v-if="showModalSeguimento === true"
      ref="modalSeguimentoListing"
      :tipo="tipoSeguimento"
      :id-lead="leadSeguimento"
      :block-field-tipo="1"
    />

    <component
      :is="ModalRecusarVersao"
      v-if="showModalRecusa === true"
      ref="modalRecusarVersao"
    />

    <component
      :is="ModalMotivo"
      v-if="showModalMotivo === true"
      ref="modalMotivo"
    />

    <form-create-new-visit
      v-if="showFormNewVisit===true"
      ref="formNewVisit"
      :pid-listing="form_data.sw012s02"
      :change-listing="false"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
      @close-modal="showFormNewVisit = false"
    />

    <visit-modal-detail
      v-if="showProcessoModalDetail===true"
      ref="processoModalDetail"
      :row="rowModalAction"
      :separador="tabSelectFilterVisits"
      :view-buttons="false"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <form-change-agent
      v-if="showFormChangeAgent===true"
      ref="formChangeAgent"
      :filter-change-agent="fChangeAgent"
      :submit-change-agent="submitChangeAgent"
      :show-msg-error-request="showMsgErrorRequest"
      :show-msg-success-request="showMsgSuccessRequest"
    />

    <price-history
      v-if="showFormPrices===true"
      ref="formPrices"
      :data-prices="dataPrices"
    />

    <component
      :is="ModalAcessibilidade"
      v-if="showModalAcesso === true"
      ref="modalAcessibilidade"
    />

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import listingsModule from '@store-modules/listings/form/index'
import crmVisitsModule from '@store-modules/crm/visitas/listview/index'
import crmProposalsListModule from '@store-modules/crm/proposals/listview/index'
import crmProposalsEventsModule from '@store-modules/crm/proposals/listview/events'
import { mapGetters } from 'vuex'
import { useRouter, showModalMessage } from '@core/utils/utils'
import { VeeValidateLoadLocale } from '@core-custom/mixins/geral'
import FormLeftSidebar from './leftSidebar.vue'
import TabsFormData from './tabs.vue'
import { mixFormGeral } from './mixins'

export default {
  components: {
    FormLeftSidebar,
    TabsFormData,
    formCreateNewVisit: () => import(/* webpackChunkName: "modal-listing-create-new-visit" */ /* webpackPrefetch: true */ '@/views/modules/crm/visitas/form/modalDashboard.vue'),
    visitModalDetail: () => import(/* webpackChunkName: "modal-listing-visits-detail" */ /* webpackPrefetch: true */ '@/views/modules/crm/visitas/listview/modalDetail.vue'),
    proposalDetail: () => import(/* webpackChunkName: "modal-listing-proposal-detail" */ /* webpackPrefetch: true */ '@/views/modules/crm/proposals/listview/detail.vue'),
    formChangeAgent: () => import(/* webpackChunkName: "modal-change-agent" */ /* webpackPrefetch: true */ '@/views/modules/listings/form/form_data/modal/changeAgent.vue'),
    priceHistory: () => import(/* webpackChunkName: "modal-price-history" */ /* webpackPrefetch: true */ '@/views/modules/listings/form/form_data/modal/priceHistory.vue'),
  },
  mixins: [mixFormGeral, VeeValidateLoadLocale],
  props: {
    viewDetail: {
      type: Boolean,
      required: false,
      default: false,
    },
    draft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      prevRoute: null,
      errorLoadingPage: false,
      fAutosave: false,
      fSaving: false,
      clicked: false,
      rating: {},
      months: {},
      planos: [],
      dtaStart: '',
      dtaEnd: '',
      showButtonNext: 0,

      //  modal
      showModalRating: false,
      showModalMediacao: false,
      showModalPlanos: false,
      showModalRecusa: false,
      showModalMotivo: false,
      showFormNewVisit: false,
      showProcessoModalDetail: false,
      showProposalDetail: false,
      showFormChangeAgent: false,
      showFormPrices: false,
      showModalAcesso: false,

      // Detail visit
      rowModalAction: {},
      tabSelectFilterVisits: 0,

      // Detail proposal
      idProposal: '',

      // seguimento
      showModalSeguimento: false,
      tipoSeguimento: '',
      leadSeguimento: '',

      // Filtro alteração do consultor
      fChangeAgent: {},

      // Dados histórico preços
      dataPrices: [],
    }
  },
  computed:
  {
    ...mapGetters('listings_form', ['form_data', 'load_data_geral', 'vars', 'appConfig', 'keyReg', 'idLeadAssoc', 'idBankAssoc', 'idAgent', 'rulesFields', 'rulesFieldsAngariacao',
      'rulesFieldsPreAngariacao', 'rulesAttrFields', 'rulesAttrFieldsAngariacao', 'rulesAttrFieldsPreAngariacao', 'loadTabMedia', 'formModeView', 'filterChangeAgent', 'tabContentStepShow']),
    ...mapGetters('auth', ['currentUser']),
    mqShallShowLeftSidebar: {
      get() { return this.$store.getters['listings_form/mqShallShowLeftSidebar'] },
      set(newValue) {
        this.$store.commit('listings_form/setMqShallShowLeftSidebar', newValue)
      },
    },
  },
  watch: {
    form_data: {
      handler() {
        this.autoSave()
      },
      deep: true,
    },
    $route(to, from) {
      if (to !== from) {
        this.paramsUrl = to.params
        this.$store.dispatch('listings_form/setParamsUrl', this.paramsUrl)
        this.renderCreated()
      }
    },
  },
  async created() {
    await this.renderCreated()
  },
  async mounted() {
    // Para atualizar os botoes de navegacao
    this.$refs.tabsFormData.checkStatusArrowNavDisableFooter()
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const self = vm
      self.prevRoute = from

      if (from.name === null) {
        if ((localStorage.getItem(vm.$router.currentRoute.name) !== null) && (localStorage.getItem(vm.$router.currentRoute.name) !== '')) {
          localStorage.removeItem(vm.$router.currentRoute.name)
        }
      } else if (to.name.includes('-view')) {
        if ((from.name !== null) && (from.name.includes('-edit'))) {
          //
        } else {
          localStorage.setItem(vm.$router.currentRoute.name, self.prevRoute.path)
        }
      }

      if (self.prevRoute.name !== null && !self.prevRoute.name.includes('-view') && !self.prevRoute.name.includes('-edit')) {
        localStorage.setItem('urlBackListings', self.prevRoute.path)
      }
    })
  },
  methods: {
    async renderCreated() {
      try {
        this.$root.$emit('app::loading', true)

        const { id, status } = this.paramsUrl

        // Faz reset a todas as variaveis do formulario
        await this.$store.commit('listings_form/resetState')

        this.errorLoadingPage = false
        this.fAutosave = false
        this.fSaving = false
        this.clicked = false
        this.rating = {}
        this.months = {}
        this.planos = []
        this.dtaStart = ''
        this.dtaEnd = ''
        this.showButtonNext = 0

        //  modal
        this.showModalRating = false
        this.showModalMediacao = false
        this.showModalPlanos = false
        this.showModalRecusa = false
        this.showModalMotivo = false
        this.showFormNewVisit = false
        this.showProcessoModalDetail = false
        this.showProposalDetail = false
        this.showFormChangeAgent = false
        this.showFormPrices = false
        this.showModalAcesso = false

        // Detail visit
        this.rowModalAction = {}
        this.tabSelectFilterVisits = 0

        // Detail proposal
        this.idProposal = ''

        // seguimento
        this.showModalSeguimento = false
        this.tipoSeguimento = ''
        this.leadSeguimento = ''

        // Filtro alteração do consultor
        this.fChangeAgent = {}

        // Dados histórico preços
        this.dataPrices = []

        await store.dispatch('listings_form/getDataFormBase', { keyReg: id || '', statusP: status || '', page: this.$route.name || '' }).catch(async error => {
          this.errorLoadingPage = true
          this.showMsgErrorRequest(error)
          await this.redirectPageBack()
        })

        if (this.errorLoadingPage === false) {
          await this.$refs.tabsFormData.$refs.formSteps.getTabs().forEach(o => {
            if (typeof o.$children[0].init === 'function') {
              o.$children[0].init()
            }
          })

          if (typeof this.form_data.sw012s01 === 'undefined' || this.form_data.sw012s01 === null || this.form_data.sw012s01 === '') {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s466', value: this.load_data_geral.typeRegime.find(o => Number(o.id) === 1) })
          } else if (typeof this.vars.sw012.sw012s466 !== 'undefined' && this.vars.sw012.sw012s466 !== null) {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s466', value: this.load_data_geral.typeRegime.find(o => Number(o.id) === this.vars.sw012.sw012s466) })
          }

          if ('sw012s01' in this.vars.sw012) {
            await this.renderDataEditionGeral()
            await this.renderDataEditionDados()
            await this.renderDataEditionMorada()
            await this.renderDataEditionDescritivos()
            await this.renderDataAtributos()
          } else if (Number(this.load_data_geral.formPreAngariacao) === 1) {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s64', value: this.load_data_geral.listingStates.find(o => Number(o.sw018s01) === 9) })
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s68', value: this.load_data_geral.arrayMeses.find(o => Number(o.id) === 6) })
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s542', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === 0) })
          } else {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s64', value: this.load_data_geral.listingStates.find(o => Number(o.sw018s01) === 5) })
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s68', value: this.load_data_geral.arrayMeses.find(o => Number(o.id) === 6) })
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s542', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === 0) })
          }

          if (Object.keys(this.load_data_geral.especialistas).length > 0) {
            if (typeof this.vars.sw012.sw012s390 !== 'undefined' && this.vars.sw012.sw012s390 !== null && this.vars.sw012.sw012s390 !== '') {
              await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s390', value: this.load_data_geral.especialistas.find(o => Number(o.id) === Number(this.vars.sw012.sw012s390)) })
            } else {
              await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s390', value: this.load_data_geral.especialistas.find(o => Number(o.id) === Number(atob(this.currentUser.id))) })
            }

            await store.dispatch('listings_form/getScriptsVenda', { sw012s04: btoa(this.currentUser.extras.idLeader) })
            await store.dispatch('listings_form/getVenturesByAgent', { sw012s04: btoa(this.currentUser.extras.idLeader) })
          }

          if (typeof this.form_data.sw012s71 !== 'undefined' && this.form_data.sw012s71 !== null && this.form_data.sw012s71 !== '') {
            await this.getModalMotivo()
          }

          store.dispatch('listings_form/setLoaded')

          this.$root.$emit('app::loading', false)
        }
      } catch (err) {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(new Error(this.$t('Problema ao carregar a informação')))
      }
    },
    async autoSave() {
      if (store.hasModule('listings_form') && this.formModeView === false && this.fAutosave === false && this.fSaving === false) {
        this.fAutosave = true
        setTimeout(() => {
          this.$store.dispatch('listings_form/autosaveSW012')
          this.fAutosave = false
        }, 20000)
      }
    },
    async renderDataEditionGeral() {
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s64', value: this.load_data_geral.listingStates.find(o => Number(o.sw018s01) === Number(this.vars.sw012.sw012s64)) })
    },
    async renderDataEditionDados() {
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s312', value: this.load_data_geral.typeCategory.find(o => Number(o.sw087s01) === Number(this.vars.sw012.sw012s312)) })
      if (typeof this.form_data.sw012s312 !== 'undefined' && this.form_data.sw012s312 !== null) {
        await store.dispatch('listings_form/getTipoImoveisByCategoria', this.form_data.sw012s312.sw087s03)
      }

      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s07', value: this.load_data_geral.typeListings.find(o => Number(o.id) === Number(this.vars.sw012.sw012s07)) })
      await store.dispatch('listings_form/setAtributosByTipoImovel', this.form_data.sw012s07)

      if (typeof this.form_data.sw012s07 !== 'undefined' && this.form_data.sw012s07 !== null) {
        await store.dispatch('listings_form/getSubTipoImoveis', this.form_data.sw012s07.id)
      }

      if (this.form_data.sw012s08 !== null) {
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08', value: this.load_data_geral.typologiesClasses.find(o => Number(o.sw005s01) === Number(this.vars.sw012.sw012s08)) })

        const numQ = Number(this.form_data.sw012s08.sw005s06)
        await store.dispatch('listings_form/getTipologiasExtras', this.form_data.sw012s08.sw005s01)
        await store.commit('listings_form/setArraySuites', numQ)
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s08_plus', value: this.load_data_geral.typologiesExtra.find(o => Number(o.sw005s01) === Number(this.vars.sw012.sw012s08_plus)) })
      } else {
        await store.dispatch('listings_form/getTipologiasExtras', '')
        await store.commit('listings_form/setArraySuites', 70)
      }

      if (this.vars.sw012.sw019s03 > 0) {
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw019s03', value: this.load_data_geral.subTypeListings.find(o => Number(o.sw004s01) === Number(this.vars.sw012.sw019s03)) })
      }

      const aTiposImoveis = []
      this.load_data_geral.subTiposImoveisSel.forEach(element => {
        const oValue = this.load_data_geral.subTiposImoveis.find(o => Number(o.sw004s01) === Number(element))
        if (oValue) {
          aTiposImoveis.push(oValue)
        }
      })

      await store.dispatch('listings_form/updateFieldValue', { field: 'sw019s03', value: aTiposImoveis })

      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s06', value: this.load_data_geral.typeBusiness.find(o => Number(o.sw007s01) === Number(this.vars.sw012.sw012s06)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s09', value: this.load_data_geral.typeLands.find(o => Number(o.sw008s01) === Number(this.vars.sw012.sw012s09)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s10', value: this.load_data_geral.finality.find(o => Number(o.sw009s01) === Number(this.vars.sw012.sw012s10)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s11', value: this.load_data_geral.conditions.find(o => Number(o.sw010s01) === Number(this.vars.sw012.sw012s11)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s26', value: this.load_data_geral.arrayPermuta.find(o => Number(o.id) === Number(this.vars.sw012.sw012s26)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s19', value: this.load_data_geral.energy.find(o => Number(o.sw006s01) === Number(this.vars.sw012.sw012s19)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s68', value: this.load_data_geral.arrayMeses.find(o => Number(o.id) === Number(this.vars.sw012.sw012s68)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s230', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s230)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s305', value: this.load_data_geral.arrayCondominioYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s305)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s314', value: this.load_data_geral.arrayCondominio.find(o => Number(o.id) === Number(this.vars.sw012.sw012s314)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s363', value: this.load_data_geral.array70.find(o => Number(o.id) === Number(this.vars.sw012.sw012s363)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s376', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s376)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s466', value: this.load_data_geral.typeRegime.find(o => Number(o.id) === Number(this.vars.sw012.sw012s466)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s483', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s483)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s492', value: this.load_data_geral.typeGarage.find(o => Number(o.id) === Number(this.vars.sw012.sw012s492)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s516', value: this.load_data_geral.rentPeriod.find(o => Number(o.id) === Number(this.vars.sw012.sw012s516)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s517', value: this.appConfig.aComissionPercentageShare.find(o => Number(o.id) === Number(this.vars.sw012.sw012s517)) })

      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s542', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s542)) })

      if ((this.vars?.sw012?.sw012s542?.id !== undefined) && (Number(this.vars.sw012.sw012s542.id) === 1)) {
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s543', value: this.load_data_geral.enterprises.find(o => Number(o.id) === Number(this.vars.sw012.sw012s543)) })
      }
    },
    async renderDataEditionMorada() {
      if (this.countryApp.toUpperCase() === 'PT') {
        const oDataLocalization = {
          codigoPostal: this.vars.sw012.sw012s27 || null,
          distrito: this.vars.sw012.sw012s28 || null,
          concelho: this.vars.sw012.sw012s29 || null,
          freguesia: this.vars.sw012.sw012s30 || null,
          zona: this.vars.sw012.sw012s298 || null,
          rua: this.vars.sw012.sw012s299 || null,
          numeroPorta: this.vars.sw012.sw012s31 || null,
          andar: this.vars.sw012.sw012s306 || null,
          divisao: this.vars.sw012.sw012s307 || null,
          ajudaMorada: this.vars.sw012.sw012s32 || null,
          nomeEdificio: this.vars.sw012.sw012s313 || null,
        }

        await this.$refs.tabsFormData.$refs.tabContentFormLocalizacao.$children[0].$refs.location.loadDataForm(oDataLocalization)
      } else if (this.countryApp.toUpperCase() === 'ES') {
        const oDataLocalization = {
          codigoPostal: this.vars.sw012.sw012s27 || null,
          comunidade: this.vars.sw012.sw012s28 || null,
          provincia: this.vars.sw012.sw012s29 || null,
          comarca: this.vars.sw012.sw012s30 || null,
          zona: this.vars.sw012.sw012s298 || null,
          rua: this.vars.sw012.sw012s299 || null,
          ruaManual: this.vars.sw012.sw012s65 || null,
          numeroPorta: this.vars.sw012.sw012s31 || null,
          andar: this.vars.sw012.sw012s306 || null,
          fracao: this.vars.sw012.sw012s307 || null,
          ajudaMorada: this.vars.sw012.sw012s32 || null,
          nomeEdificio: this.vars.sw012.sw012s313 || null,
          municipio: this.vars.sw012.sw012s331 || null,
        }

        await this.$refs.tabsFormData.$refs.tabContentFormLocalizacao.$children[0].$refs.location.loadDataForm(oDataLocalization)
      }
    },
    async renderDataEditionDescritivos() {
      if (this.load_data_geral.descriptonScripts.length > 0) {
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s536', value: this.load_data_geral.descriptonScripts.find(o => Number(o.sw361s09) === Number(this.vars.sw012.sw012s536)) })
      }

      this.load_data_geral.languages.forEach(async item => {
        Object.entries(this.$refs.tabsFormData.$refs.tabContentFormDescritivo.$children[0].$refs).forEach(element => {
          if (element[0] === `${this.countryApp}_description`) {
            let aKeys = []
            if (typeof this.vars.sw012.sw012s36 !== 'undefined' && this.vars.sw012.sw012s36 !== null && this.vars.sw012.sw012s36 !== '') {
              aKeys = this.vars.sw012.sw012s36.split(',')
            }

            const oDataDescription = {
              nameWebsite: this.vars.sw012.sw012s67 || null,
              short: this.vars.sw012.sw012s34 || null,
              complete: this.vars.sw012.sw012s35 || null,
              script: (typeof this.load_data_geral.descriptonTrads[this.countryApp.toLowerCase()] !== 'undefined' && typeof this.load_data_geral.descriptonTrads[this.countryApp.toLowerCase()].sw012s536_desc !== 'undefined' ? this.load_data_geral.descriptonTrads[this.countryApp.toLowerCase()].sw012s536_desc : null),
              keyWords: aKeys,
            }
            element[1][0].loadDataForm(oDataDescription)
          } else if (typeof item.sw033s03 !== 'undefined' && item.sw033s03 !== null && item.sw033s03 !== '' && element[0] === `${item.sw033s03.toUpperCase()}_description`) {
            let aKeys = []
            if (typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()] !== 'undefined' && typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s36 !== 'undefined') {
              aKeys = this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s36.split(',')
            }

            const oDataDescription = {
              nameWebsite: (typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()] !== 'undefined' && typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s67 !== 'undefined' ? this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s67 : null),
              short: (typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()] !== 'undefined' && typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s34 !== 'undefined' ? this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s67 : null),
              complete: (typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()] !== 'undefined' && typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s35 !== 'undefined' ? this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s35 : null),
              script: (typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()] !== 'undefined' && typeof this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s536_desc !== 'undefined' ? this.load_data_geral.descriptonTrads[item.sw033s03.toLowerCase()].sw012s536_desc : null),
              keyWords: aKeys,
            }

            element[1][0].loadDataForm(oDataDescription)
          }
        })
      })
    },
    async renderDataAtributos() {
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s13', value: this.load_data_geral.array70.find(o => Number(o.id) === Number(this.vars.sw012.sw012s13)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s39', value: this.load_data_geral.garages.find(o => Number(o.id) === Number(this.vars.sw012.sw012s39)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s40', value: this.load_data_geral.places.find(o => Number(o.id) === Number(this.vars.sw012.sw012s40)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s41', value: this.load_data_geral.array20.find(o => Number(o.id) === Number(this.vars.sw012.sw012s41)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s42', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s42)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s43', value: this.load_data_geral.array70.find(o => Number(o.id) === Number(this.vars.sw012.sw012s43)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s44', value: this.load_data_geral.array70.find(o => Number(o.id) === Number(this.vars.sw012.sw012s44)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s47', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s47)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s126', value: this.load_data_geral.array20.find(o => Number(o.id) === Number(this.vars.sw012.sw012s126)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s133', value: this.load_data_geral.array70.find(o => Number(o.id) === Number(this.vars.sw012.sw012s133)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s137', value: this.load_data_geral.array70.find(o => Number(o.id) === Number(this.vars.sw012.sw012s137)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s143', value: this.load_data_geral.arraySuites.find(o => Number(o.id) === Number(this.vars.sw012.sw012s143)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s391', value: this.load_data_geral.arrayYesNo.find(o => Number(o.id) === Number(this.vars.sw012.sw012s391)) })
      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012_attr_obras-no-imovel', value: this.load_data_geral.arrayConstruction.find(o => Number(o.id) === Number(this.vars.sw012['sw012_attr_obras-no-imovel'])) })

      if (typeof this.vars.sw012['sw012_attr_orientacao-solar'] !== 'undefined' && this.vars.sw012['sw012_attr_orientacao-solar'] !== null) {
        const aOrientacao = []
        this.vars.sw012['sw012_attr_orientacao-solar'].forEach(async item => {
          const oValue = this.load_data_geral.solarOrientations.find(o => Number(o.id) === Number(item))
          if (oValue) {
            aOrientacao.push(oValue)
          }

          await store.dispatch('listings_form/updateFieldValue', { field: 'sw012_attr_orientacao-solar', value: aOrientacao })
        })
      }
    },
    async actionForm(typeAction) {
      if (typeAction === 'edit_reg') {
        const urlPrevRoute = `/listings/edit/${btoa(this.vars.sw012.sw012s01)}`
        if (urlPrevRoute === this.$router.currentRoute.fullPath) {
          this.$router.go()
        } else {
          this.$router.push({ path: urlPrevRoute }).then(() => {
            this.$root.$emit('app::loading', false)
          })
        }
      } else if (typeAction === 'back_page') {
        await this.redirectPageBack()
      } else if (typeAction === 'recusar_list') {
        await this.getModalRecusa()
      } else if (typeAction === 'aprovar_list') {
        const forceFillWebsiteConfig = this.load_data_geral.forceWebsiteConfig

        if (Number(forceFillWebsiteConfig) === 2) {
          await this.aprovarListing()
        } else {
          await store.commit('listings_form/setForceWebsiteConfig', 2)

          await this.getModalPortaisListing()
        }
      } else if (typeAction === 'contrato_cancelado') {
        await this.cancelarContradoMediacao()
      } else if (typeAction === 'reativar_contrato') {
        await this.getModalMediacao(1)
      }
    },
    async saveForm(typeAction) {
      if (typeAction === 'save_form') {
        let estadoCreate = ''
        let typeAngariacao = ''

        if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null) {
          estadoCreate = Number(this.form_data.sw012s64.sw018s01)
        }

        if (typeof this.form_data.sw012s379 !== 'undefined' && this.form_data.sw012s379 !== null) {
          typeAngariacao = Number(this.form_data.sw012s379)
        }

        this.$root.$emit('app::loading', true)

        if (estadoCreate) {
          if (![5, 9, 10].includes(Number(this.currentUser.id_role))) {
            if (Number(this.load_data_geral.formPreAngariacao) === 1) {
              await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s64', value: this.load_data_geral.listingStates.find(o => Number(o.sw018s01) === 9) })
              estadoCreate = 9
            }
          }

          await store.commit('listings_form/setRules', this.rulesFieldsAngariacao)
          await store.commit('listings_form/setAttrRules', this.rulesAttrFieldsAngariacao)

          if (estadoCreate === 9) {
            if ([4, 7].includes(Number(this.currentUser.id_role)) && ((typeof this.idLeadAssoc === 'undefined' || this.idLeadAssoc === null || this.idLeadAssoc === '') && (typeof this.idBankAssoc === 'undefined' || this.idBankAssoc === null || this.idBankAssoc === ''))) {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(new Error(this.$t('Tem de adicionar um proprietário')))
            } else if (typeof this.form_data.sw012s04 === 'undefined' || this.form_data.sw012s04 === null || this.form_data.sw012s04 === '') {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(new Error(this.$t('Tem de adicionar um consultor')))
            } else {
              await store.commit('listings_form/setRules', this.rulesFieldsPreAngariacao)
              await store.commit('listings_form/setAttrRules', this.rulesAttrFieldsPreAngariacao)
              await store.dispatch('listings_form/rulesValidateVentureExclusive', { status: estadoCreate })

              await this.validadeFieldsListings()
              await this.getModalRatingListing(this.keyReg, true, 0, true)
            }
          } else if (estadoCreate === 5) {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: 1 })
            await store.commit('listings_form/setRules', this.rulesFieldsPreAngariacao)
            await store.commit('listings_form/setAttrRules', this.rulesAttrFieldsPreAngariacao)
            await store.dispatch('listings_form/rulesValidateVentureExclusive', { status: estadoCreate })

            await this.validadeFieldsListings()

            if (typeAngariacao === 1) {
              this.$root.$emit('app::loading', true)

              await this.validateForm()
            } else {
              await this.getModalRatingListing(this.keyReg, true, 0, true)
            }
          } else {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: 1 })
            await store.commit('listings_form/setRules', this.rulesFieldsPreAngariacao)
            await store.commit('listings_form/setAttrRules', this.rulesAttrFieldsPreAngariacao)
            await store.dispatch('listings_form/rulesValidateVentureExclusive')
            await this.validadeFieldsListings()

            this.$root.$emit('app::loading', true)

            await this.validateForm()
          }
        } else {
          this.$root.$emit('app::loading', false)
          this.showMsgErrorRequest(new Error(this.$t('Escolha um estado')))
        }
      } else if (['save_submit', 'save_submit_version'].includes(typeAction)) {
        let estadoCreate = ''
        let createSeguimentoPreAngariacao = 0

        if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null) {
          estadoCreate = Number(this.form_data.sw012s64.sw018s01)
        }

        await store.commit('listings_form/setRules', this.rulesFieldsAngariacao)
        await store.commit('listings_form/setAttrRules', this.rulesAttrFieldsAngariacao)

        if (estadoCreate) {
          if (estadoCreate === 9) {
            if ([5, 9, 10].includes(Number(this.currentUser.id_role))) {
              await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: 1 })
            }
            await store.commit('listings_form/setRules', this.rulesFieldsPreAngariacao)
            await store.commit('listings_form/setAttrRules', this.rulesAttrFieldsPreAngariacao)

            await this.validadeFieldsListings()
          } else {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: '' })
            await this.validadeFieldsListings()
            createSeguimentoPreAngariacao = 1
          }

          this.$root.$emit('app::loading', true)

          await store.dispatch('listings_form/checkTotalImagesByListing').then(async res => {
            this.$root.$emit('app::loading', false)

            let bFieldsExtraValidate = true

            if (bFieldsExtraValidate === true && (typeof this.form_data.sw012s04 === 'undefined' || this.form_data.sw012s04 === null || this.form_data.sw012s04 === '')) {
              bFieldsExtraValidate = false
              throw new Error(this.$t('Tem de adicionar um consultor'))
            }

            if (Number(res.total) === 0) {
              bFieldsExtraValidate = false
              throw new Error(this.$t('Para submeter a angariação tem pelo menos de ter uma fotografia'))
            }

            if (bFieldsExtraValidate === true && (typeof this.form_data.sw012s33 === 'undefined' || this.form_data.sw012s33 === null)) {
              bFieldsExtraValidate = false
              throw new Error(this.$t('Tem de definir o ponto no mapa'))
            }

            if (bFieldsExtraValidate === true && [4, 7].includes(Number(this.currentUser.id_role)) && ((typeof this.idLeadAssoc === 'undefined' || this.idLeadAssoc === null || this.idLeadAssoc === '') && (typeof this.idBankAssoc === 'undefined' || this.idBankAssoc === null || this.idBankAssoc === ''))) {
              bFieldsExtraValidate = false
              throw new Error(this.$t('Tem de adicionar um proprietário'))
            }

            if (bFieldsExtraValidate === true) {
              if (typeAction === 'save_submit_version') {
                await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s334', value: 1 })
              }

              if (typeAction === 'save_submit') {
                await store.dispatch('listings_form/checkListingsAssocVentureSubmit', { status: estadoCreate }).then(response => {
                  if (response !== '') {
                    throw new Error(response)
                  }
                })
              }

              // Verifica se o consultor e o lead, tem algum seguimento criado do tipo de "Pre-angariação"
              if (createSeguimentoPreAngariacao === 1) {
                let vlIdConsultorSel = this.idConsultorSel
                let vlIdLeadAssoc = this.idLeadAssoc

                if (typeof this.form_data.sw012s05Change !== 'undefined' && this.form_data.sw012s05Change !== null) {
                  vlIdLeadAssoc = this.form_data.sw012s05Change
                }

                if ([4, 7].includes(Number(this.currentUser.id_role)) && this.currentUser.id !== '') {
                  vlIdConsultorSel = this.currentUser.id
                }

                if (vlIdConsultorSel && vlIdLeadAssoc) {
                  this.$root.$emit('app::loading', true)
                  await store.dispatch('listings_form/checkValidateRulesForm', { type: 'SeguimentoPreAngariacao', consultor: vlIdConsultorSel, lead: vlIdLeadAssoc }).then(async response => {
                    this.$root.$emit('app::loading', false)

                    if (response.save === 1) {
                      await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: '0' })
                      this.validateForm()
                    } else if (response.showModalSeg === 1) {
                      // // Abre o popup de criar um seguimento rapido, para criar o seguimnento de reunião de pré-angariação
                      if (response.lead) {
                        this.tipoSeguimento = 'reuniao_pre_angariacao'
                        this.leadSeguimento = response.lead
                        this.showModalSeguimento = true

                        setTimeout(() => {
                          this.$root.$emit('app::loading', false)

                          if (this.showModalSeguimento === true) {
                            this.$refs.modalSeguimentoListing.showModal().then(async result => {
                              if (result === true) {
                                await this.$refs.modalSeguimentoListing.hideModal()
                                await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: '0' })
                                this.validateForm()
                              }
                            })
                          }
                        }, 800)
                      }
                    }
                  })
                    .catch(error => {
                      this.$root.$emit('app::loading', false)
                      this.showMsgErrorRequest(error)
                    })
                } else {
                  await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: '0' })
                  this.validateForm()
                }
              } else {
                await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: '0' })
                this.validateForm()
              }
            }
          })
            .catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
        } else {
          this.showMsgErrorRequest(new Error(this.$t('Escolha um estado')))
        }
      }
    },
    async validadeFieldsListings() {
      let estadoAngSel = ''
      let estadoAngDraftSel = ''
      let vlSW012s07 = ''
      let tipoNegocioAng = ''
      let tipoNegocioSel = ''

      if (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null) {
        estadoAngSel = Number(this.form_data.sw012s64.sw018s01)
      }

      if (typeof this.form_data.sw012s70 !== 'undefined' && this.form_data.sw012s70 !== null) {
        estadoAngDraftSel = Number(this.form_data.sw012s70)
      }

      if (typeof this.form_data.sw012s07 !== 'undefined' && this.form_data.sw012s07 !== null) {
        vlSW012s07 = Number(this.form_data.sw012s07)
      }

      if (typeof this.form_data.sw012s06 !== 'undefined' && this.form_data.sw012s06 !== null) {
        tipoNegocioAng = Number(this.form_data.sw012s06.sw007s01)
        tipoNegocioSel = Number(this.form_data.sw012s06.sw007s06)
      }

      if (Number(vlSW012s07.showcltipo) === 1) {
        if ((estadoAngSel === 9) || ((estadoAngSel === 5) && (estadoAngDraftSel === 1))) {
          if (Number(vlSW012s07.show_cl_tipologia) === 1) {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s08', value: false })
          }
        } else if (Number(vlSW012s07.show_cl_tipologia) === 1) {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s08', value: true })
        }
      }

      if (Number(vlSW012s07.showtipoterreno) === 1) {
        if ((estadoAngSel === 9) || ((estadoAngSel === 5) && (estadoAngDraftSel === 1))) {
          // Não faz nada
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s09', value: true })
        }

        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s11', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s48', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s50', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s305', value: false })

        if ((estadoAngSel === 9) || ((estadoAngSel === 5) && (estadoAngDraftSel === 1))) {
          // Não faz nada
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s51', value: true })
        }
      } else if ((estadoAngSel === 9) || ((estadoAngSel === 5) && (estadoAngDraftSel === 1))) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s305', value: false })
      } else if (Number(vlSW012s07.id) !== 0) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s305', value: true })
      }

      if (Number(vlSW012s07.showce) === 1) {
        if ((estadoAngSel === 9) || ((estadoAngSel === 5) && (estadoAngDraftSel === 1))) {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s19', value: false })
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s19', value: true })

          let vlSW012s19 = 0

          if (typeof this.form_data.sw012s19 !== 'undefined' && this.form_data.sw012s19 !== null) {
            vlSW012s19 = Number(this.form_data.vl_sw012s19.sw006s01)
          }

          if ((vlSW012s19 === 9) || (vlSW012s19 === 12) || (vlSW012s19 === 10) || (vlSW012s19 === 0)) {
            // Remove o campo da data de validade como obrigatoria
            this.$store.dispatch('listings_form/updateFieldValue', { field: 'sw012s319', value: null })
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
          } else {
            // Coloca a data de validade como obrigatoria
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: true })
          }
        }
      } else {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s19', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s319', value: false })
      }

      this.$store.dispatch('listings_form/validateRent', { clearInputs: 1 })

      this.$store.dispatch('listings_form/validateSW230', { clearInputs: 1 })

      if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s14', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s69', value: false })
      } else if (tipoNegocioAng !== 0) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s14', value: true })
      }
      this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s69', value: false })

      if (tipoNegocioSel === 1) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s14', value: false })

        if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s69', value: false })
        } else if (tipoNegocioAng !== 0) {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s69', value: true })
        }
      }

      // Se o valor do condominio (mensal) estiver como sim, valida se tem valor
      if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s25', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s314', value: false })
      } else if (typeof this.form_data.sw012s305 !== 'undefined' && this.form_data.sw012s305 !== null && Number(this.form_data.sw012s305.id) === 1) {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s25', value: true })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s314', value: true })
      } else {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s25', value: false })
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s314', value: false })
      }

      // Se for APARTAMENTO - coloca o campo "ANDAR" obrigatorio
      if ([1, 31].includes(Number(vlSW012s07.id))) {
        if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s306', value: false })
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s306', value: true })
        }
      } else if (Number(vlSW012s07.id) === 30) {
        if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
          // Não faz nada
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s143', value: false })
        }
      } else if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
        // Não faz nada
      } else {
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s306', value: false })
      }

      // Se for Pavilhão / Armazém / Loja / Comércio / Escritório / Atelier - Estúdio / Estacionamento / Quarto
      if ([6, 7, 4, 27, 5, 30, 19, 18].includes(Number(vlSW012s07.id))) {
        if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
          // Não faz nada
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s48', value: false })
        }
      }

      // Atributos Essenciais -  Remover o campo obrigatorio "Acesso apropriado a pessoas com mobilidade reduzida", caso seja "garagem, estacionamento e terreno"
      if ([15, 19, 3, 119, 155, 156, 157].includes(Number(vlSW012s07.id))) {
        if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
          // Não faz nada
        } else {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s391', value: false })
        }
      }

      // Na versao da espanha - Campo Quartos totais e WCs ao lado do menu especial
      if (['ES', 'AR'].includes(this.countryApp.toUpperCase())) {
        if (Number(vlSW012s07.show_tipo_terreno) !== 1) {
          if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s48', value: false })
          } else if (Number(vlSW012s07.fieldsatribrooms) === 1) {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s48', value: true })
          } else {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s363', value: false })
          }
        }

        // Mostra o campo da casa de banho, caso nao seja Estacionamento, Garagem e Terreno
        if (Number(vlSW012s07.id) !== 0) {
          if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s13', value: false })
          } else if (![19, 15, 3, 119, 155, 156, 157, 197, 206, 216, 228, 224].includes(Number(vlSW012s07.id))) {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s13', value: true })
          } else {
            this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s13', value: false })
          }
        }
      }

      if ((estadoAngSel === 9) || (estadoAngSel === 5 && estadoAngDraftSel === 1)) {
        if (Number(vlSW012s07.id) === 3) {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s09', value: false })
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw012s51', value: false })
        }
      }

      if (this.countryApp.toUpperCase() === 'AR') {
        // Verifica se tem algum tipo de imovel especial, caso tenha coloca o campo como obrigatorio
        this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw019s03', value: false })

        if (vlSW012s07.escolha_especial !== '') {
          this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'sw019s03', value: true })
        }
      }

      await this.$store.dispatch('listings_form/validatePAA').catch(async error => {
        this.showMsgErrorRequest(error)
      })

      if (!this.skipValidationCMI()) {
        // Validações Comissão
        await this.$store.dispatch('listings_form/validateMinCPCV', { vlMinCMI: this.form_data.sw012s110 }).catch(async error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.dispatch('listings_form/validatePercCMI', { vlPerCMI: this.form_data.sw012s15, clearInput: 1 }).catch(async error => {
          this.showMsgErrorRequest(error)
        })
      } else {
        await this.$store.dispatch('listings_form/validatePercCMI', { vlPerCMI: this.form_data.sw012s15, clearInput: 1 }).catch(() => {
          // Nao mostra o erro devido a ser rascunh ou pre angariacao pk nao sao obrigatorios
        })
      }

      await this.$store.dispatch('listings_form/validateLicenseNumber')
    },
    skipValidationCMI() {
      if (typeof this.form_data.sw012s24 === 'undefined' || this.form_data.sw012s24 === null || this.form_data.sw012s24 === '') {
        return true
      }

      if (Number(this.load_data_geral.formPreAngariacao) === 1) {
        return true
      }

      if (typeof this.form_data.sw012s70 === 'undefined' && this.form_data.sw012s70 === null && Number(this.form_data.sw012s70) === 1) {
        return true
      }

      return false
    },
    async validateForm() {
      const self = this

      if ((typeof this.clicked === 'undefined') || (this.clicked === false)) {
        this.clicked = true
        await self.$refs.tabsFormData.$refs.formSaveListingValidator.validateWithInfo()
          .then(async ({
            isValid, errors,
          }) => {
            if (isValid) {
              self.$root.$emit('app::loading', true)

              let forceFillWebsiteConfig = this.load_data_geral.forceWebsiteConfig
              let estadoCreate = (typeof this.form_data.sw012s64 !== 'undefined' && this.form_data.sw012s64 !== null ? Number(this.form_data.sw012s64.sw018s01) : '')
              const estadoCreateRascunho = (typeof this.form_data.sw012s70 !== 'undefined' && this.form_data.sw012s70 !== null ? this.form_data.sw012s70 : '')
              const idAng = this.keyReg
              const estadoActual = this.load_data_geral.currentState
              let check = true

              if ([4, 7].includes(Number(this.currentUser.id_role)) && (estadoCreate === 4 || estadoCreate === 6 || estadoCreate === 8)) {
                estadoCreate = 5 // Simula o estado PENDENTE
              }

              if (typeof idAng !== 'undefined' && idAng !== '' && typeof estadoActual !== 'undefined' && estadoActual !== '' && typeof estadoCreate !== 'undefined' && estadoCreate !== '') {
                if ((estadoCreate === 8 && estadoActual !== estadoCreate) || (estadoCreate === 4 && estadoActual !== estadoCreate)) {
                  await this.$store.dispatch('listings_form/checkPropostaAng', { keyReg: idAng, action: 1 }).catch(async error => {
                    this.showMsgErrorRequest(error)
                    check = false
                  })
                }
              }

              if (check) {
                if (estadoCreate) {
                  if (![5, 9, 10].includes(Number(this.currentUser.id_role))) {
                    if (Number(this.load_data_geral.formPreAngariacao) === 1) {
                      estadoCreate = 9
                    }
                  }
                }

                if (((estadoCreate === 5) && (estadoCreateRascunho === 1)) || ((estadoCreate === 9) && (estadoCreateRascunho === 1))) {
                  await this.submitForm()
                } else {
                  const modeSaveEditionReg = (typeof this.form_data.sw012s334 !== 'undefined' && this.form_data.sw012s334 !== null ? Number(this.form_data.sw012s334) : '')
                  let disablePanelWebsite = 0

                  if (modeSaveEditionReg !== 1) {
                    let skipValidationDateCMI = true

                    // Se for um HM
                    // Obriga escolher a data de CMI caso seja o estado Ativo e Pendente
                    if ([5, 9, 10].includes(Number(this.currentUser.id_role)) && ((estadoCreate === 5) || (estadoCreate === 1))) {
                      skipValidationDateCMI = false
                    }

                    // Se for um consultor ao editar obriga a validar a data de CMI, devido que vai ficar a versão pendente
                    if ([4, 7].includes(Number(this.currentUser.id_role))) {
                      skipValidationDateCMI = false

                      if (estadoCreate !== 4) {
                        skipValidationDateCMI = true
                      }
                    }

                    // Verifica se a data de fim do CMI, ainda e superior a data atual, caso nao seja, abre a popup para definir o intervalo
                    if (skipValidationDateCMI === false) {
                      const vlSW012s18 = (typeof this.form_data.sw012s18 !== 'undefined' && this.form_data.sw012s18 !== null ? this.form_data.sw012s18 : '')
                      const vlModalChooseContratoMediacao = ''

                      if (vlSW012s18) {
                        const sCMIEnd = +new Date(vlSW012s18)
                        const sDateNow = Date.now()

                        if ((sCMIEnd < sDateNow) || (vlModalChooseContratoMediacao === 1)) {
                          this.getModalMediacao(1)
                          disablePanelWebsite = 1
                        }
                      }
                    }
                  } else {
                    // No guardar edição nao e necessario abrir a popup de configuracao
                    forceFillWebsiteConfig = 1
                  }

                  if (forceFillWebsiteConfig === 1) {
                    await this.submitForm()
                  } else if (disablePanelWebsite === 0) {
                    // Não apresenta o painel do website nos estados "Suspenso", "Recusado" ou "Cancelado"
                    if (estadoCreate === 4 || estadoCreate === 6 || estadoCreate === 8) {
                      this.$store.commit('listings_form/setForceWebsiteConfig', 1)
                      await this.getModalRatingListing(this.keyReg, true, 0, false)
                    } else {
                      this.$store.commit('listings_form/setForceWebsiteConfig', 1)

                      await this.getModalPortaisListing()
                    }
                  }
                }
              }
            } else {
              throw errors
            }
          })
          .catch(async error => {
            this.$root.$emit('app::loading', false)
            this.showMsgErrorRequestForm(error)
          })

        setTimeout(() => {
          this.clicked = false
        }, 1000)
      }
    },
    async submitForm() {
      this.fSaving = true
      this.$root.$emit('app::loading', true)
      await this.load_data_geral.languages.forEach(async item => {
        await Object.entries(this.$refs.tabsFormData.$refs.tabContentFormDescritivo.$children[0].$refs).forEach(async element => {
          if (element[0] === `${this.countryApp.toUpperCase()}_description`) {
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s67', value: element[1][0].descriptive.nameWebsite })
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s34', value: element[1][0].descriptive.short })
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s35', value: element[1][0].descriptive.complete })
            await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s36', value: element[1][0].descriptive.keyWords })
          } else if (typeof item.sw033s03 !== 'undefined' && item.sw033s03 !== null && item.sw033s03 !== '' && element[0] === `${item.sw033s03.toUpperCase()}_description`) {
            await store.dispatch('listings_form/updateFieldValue', { field: `sw012s67_${item.sw033s03}`, value: element[1][0].descriptive.nameWebsite })
            await store.dispatch('listings_form/updateFieldValue', { field: `sw012s34_${item.sw033s03}`, value: element[1][0].descriptive.short })
            await store.dispatch('listings_form/updateFieldValue', { field: `sw012s35_${item.sw033s03}`, value: element[1][0].descriptive.complete })
            await store.dispatch('listings_form/updateFieldValue', { field: `sw012s36_${item.sw033s03}`, value: element[1][0].descriptive.keyWords })
          }
        })
      })

      const localizacao = this.$refs.tabsFormData.$refs.tabContentFormLocalizacao.$children[0].$refs.location.localization

      if (this.countryApp.toUpperCase() === 'PT') {
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s32', value: localizacao.ajudaMorada })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s31', value: localizacao.numeroPorta })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s27', value: localizacao.codigoPostal })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s28', value: (typeof localizacao.distrito !== 'undefined' && localizacao.distrito !== null ? localizacao.distrito.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s29', value: (typeof localizacao.concelho !== 'undefined' && localizacao.concelho !== null ? localizacao.concelho.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s30', value: (typeof localizacao.freguesia !== 'undefined' && localizacao.freguesia !== null ? localizacao.freguesia.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s298', value: localizacao.zona })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s299', value: (typeof localizacao.rua !== 'undefined' && localizacao.rua !== null ? localizacao.rua.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s306', value: (typeof localizacao.andar !== 'undefined' && localizacao.andar !== null ? localizacao.andar.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s307', value: localizacao.divisao })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s313', value: localizacao.nomeEdificio })
      } else if (this.countryApp.toUpperCase() === 'ES') {
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s32', value: localizacao.ajudaMorada })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s31', value: localizacao.numeroPorta })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s27', value: localizacao.codigoPostal })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s28', value: (typeof localizacao.comunidade !== 'undefined' && localizacao.comunidade !== null ? localizacao.comunidade.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s29', value: (typeof localizacao.provincia !== 'undefined' && localizacao.provincia !== null ? localizacao.provincia.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s30', value: (typeof localizacao.comarca !== 'undefined' && localizacao.comarca !== null ? localizacao.comarca.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s65', value: localizacao.ruaManual })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s298', value: (typeof localizacao.zona !== 'undefined' && localizacao.zona !== null ? localizacao.zona.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s306', value: (typeof localizacao.andar !== 'undefined' && localizacao.andar !== null ? localizacao.andar.id : null) })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s307', value: localizacao.fracao })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s313', value: localizacao.nomeEdificio })
        await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s331', value: (typeof localizacao.municipio !== 'undefined' && localizacao.municipio !== null ? localizacao.municipio.id : null) })
      }

      await this.$store.dispatch('listings_form/saveSW012', this.form_data).then(res => {
        this.$root.$emit('app::loading', false)
        this.fSaving = false
        if (res.error !== 1) {
          let msgSuccess = res.msg
          if ((msgSuccess === '' || msgSuccess === null) && Number(this.load_data_geral.formPreAngariacao) === 1) {
            msgSuccess = this.$t('Pré-angariação gravada com sucesso')
          } else if ((msgSuccess === '' || msgSuccess === null)) {
            msgSuccess = this.$t('Angariação gravada com sucesso')
          }

          this.showMsgSuccessRequest({ message: msgSuccess }).then(async () => {
            const urlPrevRoute = '/listings/list'

            if (urlPrevRoute === this.$router.currentRoute.fullPath) {
              this.$router.go()
            } else {
              this.$router.push({ path: urlPrevRoute }).then(() => {
                this.$root.$emit('app::loading', false)
              })
            }
          })
        } else {
          this.showMsgErrorRequest(new Error(res.msg))
          this.clicked = false
          this.$store.commit('listings_form/setForceWebsiteConfig', 0)
        }
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
        this.clicked = false
        this.$store.commit('listings_form/setForceWebsiteConfig', 0)
      })
    },
    async cancelForm() {
      const self = this

      const actionModal = new Promise(resolve => {
        showModalMessage(resolve)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          store.dispatch('listings_form/cancelChangesDraftUser').then(async response => {
            if (typeof response.data === 'object' && Number(response.data.error) !== 1) {
              await self.redirectPageBack()
            }
          }).catch(error => {
            throw error
          })
        }
      })
    },
    async redirectPageBack() {
      let urlPrevRoute = this.prevRoute.path
      if (urlPrevRoute === '/') {
        urlPrevRoute = '/dashboard'
      } else if (localStorage.getItem('urlBackListings') !== null && localStorage.getItem('urlBackListings') !== '' && !this.$router.currentRoute.name.includes('-edit')) {
        urlPrevRoute = localStorage.getItem('urlBackListings')
      }

      if (urlPrevRoute === this.$router.currentRoute.fullPath) {
        this.$router.go()
      } else {
        this.$router.push({ path: urlPrevRoute }).then(() => {
          this.$root.$emit('app::loading', false)
        })
      }
    },
    async getModalPortaisListing() {
      this.$root.$emit('app::loading', false)

      let descriptionEN = ''
      Object.entries(this.$refs.tabsFormData.$refs.tabContentFormDescritivo.$children[0].$refs).forEach(element => {
        if (element[0] === 'EN_description' && typeof element[1][0] !== 'undefined') {
          descriptionEN = element[1][0].descriptive
        }
      })

      await store.dispatch('listings_form/getModalWebsiteStatus', { description: descriptionEN }).then(async response => {
        if (response === true) {
          await this.$refs.modalPortaisListing.showModal().then(async result => {
            await this.$refs.modalPortaisListing.hideModal()
            if (result === true && this.formModeView !== true && this.load_data_geral.forceWebsiteConfig === 1) {
              this.$root.$emit('app::loading', true)
              this.getModalRatingListing(this.keyReg, true)
            } else if (result === true && this.formModeView === true && Number(this.load_data_geral.forceWebsiteConfig) === 2) {
              await this.aprovarListing()
            } else {
              this.clicked = false
              this.$store.commit('listings_form/setForceWebsiteConfig', 0)
            }
          })
        }
      }).catch(error => {
        throw error
      })
    },
    async getModalRatingListing(idListing, formSave, versionPending, preAngariacao, estadoCreate) {
      let formData = new FormData()

      if (formSave === true) {
        await store.dispatch('listings_form/formSerialize').then(response => {
          formData = response
        }).catch(error => {
          throw error
        })

        if (typeof this.form_data.sw012s01 !== 'undefined' && this.form_data.sw012s01 !== null) {
          formData.append('sw012s01', idListing)
        }

        if (typeof this.form_data.sw012s536 !== 'undefined' && this.form_data.sw012s536 !== null) {
          formData.append('sw012s536', this.form_data.sw012s536.sw361s09)
        } else {
          formData.append('sw012s536', '')
        }

        if (this.loadTabMedia === true) {
          formData.append('fLoadImages', 1)
          formData.append('fLoadVideos', 1)
          formData.append('fLoadRealidadeVirtual', 1)
          formData.append('fLoadImagesPrincipais', 1)
        }

        if (typeof this.form_data.sw012s105 === 'undefined' || this.form_data.sw012s105 === null || (typeof this.form_data.sw012s105 !== 'undefined' && this.form_data.sw012s105 !== null && Number(this.form_data.sw012s105) !== 1)) {
          formData.append('sw012s105', '0')
        }

        formData.append('loadLastVersion', 1)
      } else {
        formData.append('sw012s01', idListing)
        formData.append('versionPending', versionPending)
      }

      await this.load_data_geral.languages.forEach(async item => {
        await Object.entries(this.$refs.tabsFormData.$refs.tabContentFormDescritivo.$children[0].$refs).forEach(async element => {
          if (element[0] === `${this.countryApp.toUpperCase()}_description`) {
            formData.append('sw012s67', element[1][0].descriptive.nameWebsite)
            formData.append('sw012s34', element[1][0].descriptive.short)
            formData.append('sw012s35', element[1][0].descriptive.complete)
          } else if (typeof item.sw033s03 !== 'undefined' && item.sw033s03 !== null && item.sw033s03 !== '' && element[0] === `${item.sw033s03.toUpperCase()}_description`) {
            formData.append(`sw012s67_${item.sw033s03}`, element[1][0].descriptive.nameWebsite)
            formData.append(`sw012s34_${item.sw033s03}`, element[1][0].descriptive.short)
            formData.append(`sw012s35_${item.sw033s03}`, element[1][0].descriptive.complete)
          }
        })
      })

      const localizacao = this.$refs.tabsFormData.$refs.tabContentFormLocalizacao.$children[0].$refs.location.localization

      if (this.countryApp.toUpperCase() === 'PT') {
        formData.append('sw012s32', localizacao.ajudaMorada)
        formData.append('sw012s31', localizacao.numeroPorta)
        formData.append('sw012s27', localizacao.codigoPostal)
        formData.append('sw012s28', (typeof localizacao.distrito !== 'undefined' && localizacao.distrito !== null ? localizacao.distrito.id : null))
        formData.append('sw012s29', (typeof localizacao.concelho !== 'undefined' && localizacao.concelho !== null ? localizacao.concelho.id : null))
        formData.append('sw012s30', (typeof localizacao.freguesia !== 'undefined' && localizacao.freguesia !== null ? localizacao.freguesia.id : null))
        formData.append('sw012s298', localizacao.zona)
        formData.append('sw012s299', (typeof localizacao.rua !== 'undefined' && localizacao.rua !== null ? localizacao.rua.id : null))
        formData.append('sw012s306', (typeof localizacao.andar !== 'undefined' && localizacao.andar !== null ? localizacao.andar.id : null))
        formData.append('sw012s307', localizacao.divisao)
        formData.append('sw012s313', localizacao.nomeEdificio)
      } else if (this.countryApp.toUpperCase() === 'ES') {
        formData.append('sw012s32', localizacao.ajudaMorada)
        formData.append('sw012s31', localizacao.numeroPorta)
        formData.append('sw012s27', localizacao.codigoPostal)
        formData.append('sw012s28', (typeof localizacao.comunidade !== 'undefined' && localizacao.comunidade !== null ? localizacao.comunidade.id : null))
        formData.append('sw012s29', (typeof localizacao.provincia !== 'undefined' && localizacao.provincia !== null ? localizacao.provincia.id : null))
        formData.append('sw012s30', (typeof localizacao.comarca !== 'undefined' && localizacao.comarca !== null ? localizacao.comarca.id : null))
        formData.append('sw012s65', localizacao.ruaManual)
        formData.append('sw012s298', (typeof localizacao.zona !== 'undefined' && localizacao.zona !== null ? localizacao.zona.id : null))
        formData.append('sw012s306', (typeof localizacao.andar !== 'undefined' && localizacao.andar !== null ? localizacao.andar.id : null))
        formData.append('sw012s307', localizacao.fracao)
        formData.append('sw012s313', localizacao.nomeEdificio)
        formData.append('sw012s331', (typeof localizacao.municipio !== 'undefined' && localizacao.municipio !== null ? localizacao.municipio.id : null))
      }

      await this.$store.dispatch('listings_form/previewModalRating', formData).then(async res => {
        this.rating = res.data.rating || {}
        this.$root.$emit('app::loading', false)

        await this.$store.commit('listings_form/setForceWebsiteConfig', 1)
        await this.$store.dispatch('listings_form/updateRuleFieldValue', { field: 'percRating', value: res.data.rating.percentagem })

        this.showModalRating = true

        setTimeout(() => {
          this.$refs.modalRatingListing.showModal().then(async result => {
            await this.$refs.modalRatingListing.hideModal()
            if (result === true && this.formModeView !== true) {
              setTimeout(() => {
                this.showModalRating = false
                this.rating = {}
              }, 500)
              if (typeof preAngariacao !== 'undefined' && preAngariacao === true) {
                await store.dispatch('listings_form/updateFieldValue', { field: 'sw012s70', value: 1 })
                await this.validateForm()
              } else {
                this.$swal({
                  title: '',
                  html: `${this.$t('Deseja guardar a angariação?')}`,
                  icon: 'success',
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: this.$t('Sim'),
                  cancelButtonText: this.$t('Não'),
                  customClass: {
                    confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
                    cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                  },
                  buttonsStyling: false,
                }).then(async resp => {
                  if (resp.value) {
                    if ((typeof estadoCreate !== 'undefined' && [4, 6, 8].includes(estadoCreate)) || (![4, 7].includes(Number(this.currentUser.id_role)) || this.appConfig.bUsePlanoComunicacao === false)) {
                      await this.submitForm()
                    } else {
                      this.$root.$emit('app::loading', true)

                      await this.$store.dispatch('listings_form/checkPlanoComunicacao').then(async resPlano => {
                        this.$root.$emit('app::loading', false)

                        if (resPlano.check === false) {
                          this.$swal({
                            title: '',
                            html: `${this.$t('Deseja usar o plano de comunicação para esta angariação?')}`,
                            icon: 'success',
                            showCancelButton: true,
                            reverseButtons: true,
                            confirmButtonText: this.$t('Sim'),
                            cancelButtonText: this.$t('Não'),
                            customClass: {
                              confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
                              cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                            },
                            buttonsStyling: false,
                          }).then(async respPlano => {
                            if (respPlano.value) {
                              await this.getModalPlanos()
                            } else {
                              await this.submitForm()
                            }
                          })
                        } else {
                          await this.submitForm()
                        }
                      }).catch(error => {
                        this.$root.$emit('app::loading', false)
                        throw error
                      })
                    }
                  } else {
                    this.clicked = false
                    this.$store.commit('listings_form/setForceWebsiteConfig', 0)
                  }
                })
              }
            } else {
              this.clicked = false
              this.$store.commit('listings_form/setForceWebsiteConfig', 0)
            }
          })
        }, 500)
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        throw error
      })
    },
    async getModalPlanos() {
      await this.$store.dispatch('listings_form/loadListPlanosComunicacao').then(async result => {
        this.planos = result.data
        this.showModalPlanos = true
      }).catch(error => {
        throw error
      })

      setTimeout(() => {
        this.$refs.modalPlanosComunicacao.showModal().then(async result => {
          await this.$refs.modalPlanosComunicacao.hideModal()
          if (result === true) {
            this.planos = []
            await this.submitForm()
          }
        })
      }, 500)
    },
    async getModalMediacao(withButtonNext) {
      this.$root.$emit('app::loading', true)

      if (withButtonNext === 1) {
        this.showButtonNext = withButtonNext
      }

      await this.$store.dispatch('listings_form/getModalMediacao').then(async response => {
        if (typeof response.data === 'object') {
          this.showModalMediacao = true
          this.dtaStart = response.data.sw012s17
          this.dtaEnd = response.data.sw012s18

          if (this.countryApp.toUpperCase() === 'ES' && (typeof response.data.sw012s68 === 'undefined' || response.data.sw012s68 === null || response.data.sw012s68 === '')) {
            this.months = this.load_data_geral.arrayMesesOutro.find(o => Number(o.id) === 0)
          } else {
            this.months = this.load_data_geral.arrayMeses.find(o => Number(o.id) === Number(response.data.sw012s68))
          }
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      setTimeout(() => {
        this.$root.$emit('app::loading', false)

        if (this.showModalMediacao === true) {
          this.$refs.modalMediacaoListing.showModal().then(async result => {
            await this.$refs.modalMediacaoListing.hideModal()

            if (result === true && this.formModeView === true) {
              this.$root.$emit('app::loading', true)

              await this.$store.dispatch('listings_form/reativarContratoMediacao', { sw012s17: this.$refs.modalMediacaoListing.dtaStart, sw012s18: this.$refs.modalMediacaoListing.dtaEnd, sw012s68: this.$refs.modalMediacaoListing.dataMonths.id }).then(async res => {
                if (res.error === 1) {
                  this.$root.$emit('app::loading', false)
                  this.showMsgErrorRequest({ message: res.msg })
                } else {
                  this.showMsgSuccessRequest({ message: res.msg })

                  const { id, status } = this.paramsUrl

                  await store.dispatch('listings_form/getDataFormBase', { keyReg: id || '', statusP: status || '', page: this.$route.name || '' }).catch(async error => {
                    this.showMsgErrorRequest(error)
                    await this.redirectPageBack()
                  })

                  this.$root.$emit('app::loading', false)
                }
              }).catch(error => {
                this.$root.$emit('app::loading', false)
                this.showMsgErrorRequest(error)
              })
            }
          })
        }
      }, 500)
    },
    async aprovarListing() {
      this.$swal({
        title: '',
        html: this.$t('Deseja aprovar esta angariação?'),
        icon: 'success',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async resp => {
        if (resp.value) {
          this.$root.$emit('app::loading', true)
          await this.$store.dispatch('listings_form/aprovaListing', { type: 1 }).then(async res => {
            this.$root.$emit('app::loading', false)
            if (res.success === 1) {
              if ([5, 9, 10].includes(Number(this.currentUser.id_role)) && this.appConfig.bUseDashboardHM === true) {
                await this.redirectPageBack()
              } else {
                const urlPrevRoute = '/listings/pendentes'

                if (urlPrevRoute === this.$router.currentRoute.fullPath) {
                  this.$router.go()
                } else {
                  this.$router.push({ path: urlPrevRoute }).then(() => {
                    this.$root.$emit('app::loading', false)
                  })
                }
              }
            }
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        } else {
          this.$store.commit('listings_form/setForceWebsiteConfig', 0)
        }
      })
    },
    async getModalRecusa() {
      this.showModalRecusa = true

      setTimeout(() => {
        this.$refs.modalRecusarVersao.showModal().then(async result => {
          await this.$refs.modalRecusarVersao.hideModal()
          if (result === true) {
            await this.$store.dispatch('listings_form/aprovaListing', { sw012s71: this.$refs.modalRecusarVersao.txtObs, type: 2 }).then(async response => {
              if (response.success === 1) {
                if ([5, 9, 10].includes(Number(this.currentUser.id_role)) && this.appConfig.bUseDashboardHM === true) {
                  this.showMsgSuccessRequest({ message: response.msg })
                  await this.redirectPageBack()
                } else {
                  const urlPrevRoute = '/listings/pendentes'
                  if (urlPrevRoute === this.$router.currentRoute.fullPath) {
                    this.$router.go()
                  } else {
                    this.$router.push({ path: urlPrevRoute }).then(() => {
                      this.$root.$emit('app::loading', false)
                    })
                  }
                }
              }
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
        })
      }, 500)
    },
    async getModalMotivo() {
      this.showModalMotivo = true

      setTimeout(() => {
        this.$refs.modalMotivo.showModal().then(async result => {
          await this.$refs.modalMotivo.hideModal()
          if (result === true) {
            // Não faz nada
          }
        })
      }, 500)
    },
    async cancelarContradoMediacao() {
      if (typeof this.keyReg !== 'undefined' && this.keyReg !== null && this.keyReg !== '') {
        this.$root.$emit('app::loading', true)
        await this.$store.dispatch('listings_form/getDatesContratoMediacao').then(async response => {
          this.$root.$emit('app::loading', false)
          if (typeof response.data === 'object') {
            if (response.data.sw012s01 !== '') {
              this.$swal({
                title: this.$t('Cancelamento de contrato de mediação'),
                html: `${this.$t('O atual contrato expira a')} ${this.form_data.sw012s18}. ${this.$t('Para concluir o processo, pode excluir já a angariação ou agendar para a data de fim de contrato.')}`,
                icon: 'warning',
                showCancelButton: true,
                showDenyButton: true,
                reverseButtons: true,
                confirmButtonText: this.$t('Excluir já'),
                denyButtonText: `${this.$t('Excluir a')} ${this.form_data.sw012s18}`,
                cancelButtonText: this.$t('Cancelar'),
                customClass: {
                  confirmButton: 'btn btn-outline-danger ml-sm-1 btn-xs-block',
                  denyButton: 'btn btn-outline-danger ml-sm-1 mb-1 mb-sm-0 btn-xs-block',
                  cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                },
                buttonsStyling: false,
                width: '35rem',
              }).then(async result => {
                if (result.isConfirmed === true) {
                  this.$root.$emit('app::loading', true)
                  await this.$store.dispatch('listings_form/cancelarContratoMediacao', { typeAction: 1 }).then(async resp => {
                    if (resp.data.error !== 1) {
                      this.showMsgSuccessRequest({ message: resp.data.msg })

                      const { id, status } = this.paramsUrl

                      await store.dispatch('listings_form/getDataFormBase', { keyReg: id || '', statusP: status || '', page: this.$route.name || '' }).catch(async error => {
                        this.showMsgErrorRequest(error)
                        await this.redirectPageBack()
                      })

                      this.$root.$emit('app::loading', false)
                    } else {
                      this.$root.$emit('app::loading', false)
                      this.showMsgErrorRequest(new Error(resp.data.msg))
                    }
                  }).catch(error => {
                    this.$root.$emit('app::loading', false)
                    this.showMsgErrorRequest(error)
                  })
                } else if (result.isDenied === true) {
                  this.$root.$emit('app::loading', true)
                  await this.$store.dispatch('listings_form/cancelarContratoMediacao', { typeAction: 2 }).then(async resp => {
                    if (resp.data.error !== 1) {
                      this.showMsgSuccessRequest({ message: resp.data.msg })

                      const { id, status } = this.paramsUrl

                      await store.dispatch('listings_form/getDataFormBase', { keyReg: id || '', statusP: status || '', page: this.$route.name || '' }).catch(async error => {
                        this.showMsgErrorRequest(error)
                        await this.redirectPageBack()
                      })

                      this.$root.$emit('app::loading', false)
                    } else {
                      this.$root.$emit('app::loading', false)
                      this.showMsgErrorRequest(new Error(resp.data.msg))
                    }
                  }).catch(error => {
                    this.$root.$emit('app::loading', false)
                    this.showMsgErrorRequest(error)
                  })
                }
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Angariação não existe')))
            }
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else {
        this.showMsgErrorRequest(new Error(this.$t('Escolha uma angariação')))
      }
    },
    async createNewVisit() {
      this.showFormNewVisit = true
    },
    async openVisitDetail(idVisit, vlReport) {
      this.rowModalAction = { id_visita: idVisit }
      if (vlReport !== '') {
        this.tabSelectFilterVisits = 3
      } else {
        this.tabSelectFilterVisits = 1
      }

      this.showProcessoModalDetail = true
    },
    async openProposalDetail(idProposal) {
      await this.$store.dispatch('crm_proposals/previewModalProposal', { sw130s01: idProposal, action: 'all' }).then(result => {
        if (result) {
          this.showProposalDetail = true
          this.idProposal = idProposal
          this.$store.dispatch('crm_proposals/setProposalDetail', { showDetail: true }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async closeModalDetail() {
      await this.$store.dispatch('crm_proposals_events/clearSWkeepalive', { sw130s01: this.idProposal }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$store.dispatch('crm_proposals/setShowDetail', false).catch(error => {
        this.showMsgErrorRequest(error)
      })
      this.showProposalDetail = false
    },
    async changeAgent() {
      await this.$store.dispatch('listings_form/modalChangeConsultor').then(result => {
        if (result) {
          this.fChangeAgent = this.filterChangeAgent
          this.showFormChangeAgent = true
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async submitChangeAgent(newAgent, chkDocs) {
      await this.$store.dispatch('listings_form/updateChangeConsultor', { consultor: btoa(newAgent.id), nomeConsultor: newAgent.nome, transDocs: chkDocs }).then(result => {
        if (result.error !== 1) {
          this.showFormChangeAgent = false
          this.showMsgSuccessRequest({ message: result.msg })
          this.$router.go()
        } else {
          this.showMsgErrorRequest({ message: result.msg })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async openPrices() {
      await this.$store.dispatch('listings_form/getPricesLinsting').then(result => {
        if (result.error !== 1) {
          this.dataPrices = result.data.aListPrice
          this.showFormPrices = true
        } else {
          this.showMsgErrorRequest({ message: result.msg })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async getModalAcesso() {
      this.showModalAcesso = true

      setTimeout(() => {
        this.$refs.modalAcessibilidade.showModal().then(async result => {
          await this.$refs.modalAcessibilidade.hideModal()
          if (result === true) {
            // Não faz nada
          }
        })
      }, 500)
    },
    async showMsgErrorRequestForm(error) {
      let messageAlertError = ''

      if (error instanceof Error) {
        if (error.message !== '') {
          messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$t('Alerta')}</h3>`
          messageAlertError += `<p class="mb-1">${error.message}</p>`
        }
      } else if (Object.keys(error).length > 0) {
        if ((Object.keys(error).length === 1) && ('message' in error) && (error.message === '')) {
          messageAlertError = ''
        } else {
          messageAlertError += `<h3 class="text-center text-danger mb-2">${this.$tc('Preencha o campo obrigatório', Object.keys(error).length)}</h3>`
          Object.keys(error).forEach(key => {
            if (error[key].length > 0) {
              error[key].forEach(txt => {
                messageAlertError += `<p class="mb-1">${txt}</p>`
              })
            }
          })
        }
      }

      if (messageAlertError !== '') {
        this.$swal({
          title: '',
          html: messageAlertError,
          icon: '',
          width: 600,
          confirmButtonText: this.$t('Fechar'),
          customClass: {
            confirmButton: 'btn btn-outline-primary',
            htmlContainer: 'text-left',
            content: 'pl-0 pr-0 pl-sm-2 pr-sm-2',
          },
          buttonsStyling: false,
        })
      }
    },
  },
  setup(props) {
    const LISTINGS_MODULE_NAME = 'listings_form'
    const CRM_VISITS_MODULE_NAME = 'crm_visitas_listview'
    const CRM_PROPOSALS_MODULE_NAME_LIST = 'crm_proposals'
    const CRM_PROPOSALS_MODULE_NAME_EVENTS = 'crm_proposals_events'

    if (!store.hasModule(LISTINGS_MODULE_NAME)) {
      store.registerModule(LISTINGS_MODULE_NAME, listingsModule)

      onUnmounted(() => {
        if (store.hasModule(LISTINGS_MODULE_NAME)) store.unregisterModule(LISTINGS_MODULE_NAME)
      })
    }

    if (!store.hasModule(CRM_VISITS_MODULE_NAME)) {
      store.registerModule(CRM_VISITS_MODULE_NAME, crmVisitsModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_VISITS_MODULE_NAME)) store.unregisterModule(CRM_VISITS_MODULE_NAME)
      })
    }

    if (!store.hasModule(CRM_PROPOSALS_MODULE_NAME_LIST)) {
      store.registerModule(CRM_PROPOSALS_MODULE_NAME_LIST, crmProposalsListModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_PROPOSALS_MODULE_NAME_LIST)) store.unregisterModule(CRM_PROPOSALS_MODULE_NAME_LIST)
      })
    }

    if (!store.hasModule(CRM_PROPOSALS_MODULE_NAME_EVENTS)) {
      store.registerModule(CRM_PROPOSALS_MODULE_NAME_EVENTS, crmProposalsEventsModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_PROPOSALS_MODULE_NAME_EVENTS)) store.unregisterModule(CRM_PROPOSALS_MODULE_NAME_EVENTS)
      })
    }

    const { route } = useRouter()
    const paramsUrl = route.value.params
    store.dispatch('listings_form/setParamsUrl', paramsUrl)
    store.dispatch('listings_form/setTypeAction', props.typeAction || '')

    if (props.viewDetail === true) {
      store.dispatch('listings_form/setModeViewDetail', 1)
    } else {
      store.dispatch('listings_form/setModeViewDetail', '')
    }

    if (props.draft === true) {
      store.dispatch('listings_form/setModeDraft', 1)
    } else {
      store.dispatch('listings_form/setModeDraft', '')
    }

    const countryApp = store.getters['auth/countryApp'].toUpperCase()

    const ModalPortaisListing = () => import(/* webpackChunkName: "listing-new-modal-portais" */ /* webpackPrefetch: true */ './form_data/modal/portais.vue')
    const ModalRatingListing = () => import(/* webpackChunkName: "listing-new-modal-rating" */ /* webpackPrefetch: true */ './form_data/modal/rating.vue')
    const ModalPlanosComunicacao = () => import(/* webpackChunkName: "listing-new-modal-planos-comunicacao" */ /* webpackPrefetch: true */ './form_data/modal/planosComunicacao.vue')
    const ModalMediacaoListing = () => import(/* webpackChunkName: "listing-new-modal-mediacao" */ /* webpackPrefetch: true */ './form_data/modal/mediacao.vue')
    const ModalSeguimentoListing = () => import(/* webpackChunkName: "listing-new-modal-seguimento" */ /* webpackPrefetch: true */ './form_data/modal/seguimento.vue')
    const ModalRecusarVersao = () => import(/* webpackChunkName: "listing-new-modal-recusa" */ /* webpackPrefetch: true */ './form_data/modal/recusaVersao.vue')
    const ModalMotivo = () => import(/* webpackChunkName: "listing-new-modal-motivo" */ /* webpackPrefetch: true */ './form_data/modal/motivo.vue')
    const ModalAcessibilidade = () => import(/* webpackChunkName: "listing-new-modal-acesso" */ /* webpackPrefetch: true */ './form_data/modal/acesso.vue')

    return {
      paramsUrl,
      countryApp,
      ModalPortaisListing,
      ModalRatingListing,
      ModalPlanosComunicacao,
      ModalMediacaoListing,
      ModalSeguimentoListing,
      ModalRecusarVersao,
      ModalMotivo,
      ModalAcessibilidade,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
